<template>
  <q-page padding>
    <CouponAndOfferGenerator />
  </q-page>
</template>

<script>
import CouponAndOfferGenerator from '../../components/api/CouponAndOfferGenerator';

export default {
  components: { CouponAndOfferGenerator },
  name: 'CouponAndOffer'
};
</script>

<style scoped></style>
