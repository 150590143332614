<template>
  <q-card flat class="justify-center creative">
    <q-card-section>
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('creatives_api') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/ " />
        <q-breadcrumbs-el :label="$t('creatives_api')" exact />
      </q-breadcrumbs>
    </q-card-section>
    <q-card-section>
      <q-banner class="bg-info text-white q-mb-md" rounded>
        <q-item>
          <q-item-section top avatar>
            <q-icon name="mdi-information-outline" size="lg" />
          </q-item-section>
          <q-item-section>
            <q-item-label caption lines="3" class="text-white">
              Clique
              <a
                href="https://drive.google.com/file/d/1Ql0vzuQGZjHcbN0Z-6nGI13nZ0l3NuNn/view"
                target="_blank"
                class="q-mt-md"
                style="text-decoration: none"
              >
                <strong>aqui</strong>
              </a>
              para saber mais.
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-banner>
    </q-card-section>
    <q-separator spaced />
    <q-card-section class="row q-col-gutter-md">
      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.id" :label="$t('affiliate_id')">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.id)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Copiar ID </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="affiliateData.token" label="Token">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(affiliateData.token)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar Token
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>

      <div class="col-6">
        <select-request
          :v-model="channel"
          outlined
          :endpoint="`redirect?app=AFFILIATE&path=/api/get/affiliate/site_active/list/${getProfiles.affiliate_id}`"
          option-label="name"
          option-value="id"
          :label="$t('select_channel')"
          clearable
          @update="(value) => (channel = value === null ? null : value.id)"
        />
      </div>

      <div class="col-6">
        <q-input outlined readonly type="text" v-model="channelID" :label="$t('channel_id')"> </q-input>
      </div>

      <div class="col-6">
        <q-select
          v-model="display"
          :options="optionsDisplay"
          :label="$t('exhibition_mode')"
          :rules="[isRequired]"
          outlined
        />
      </div>

      <div class="col-6">
        <q-select
          v-model="creativeType"
          :options="creativeTypesOptions"
          option-label="name"
          :label="$t('creative_type')"
          :rules="[isRequired]"
          outlined
        />
      </div>

      <div class="col-12">
        <q-input outlined readonly type="text" :value="getApiLink" :label="$t('creatives_api_url')">
          <template v-slot:append>
            <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(getApiLink)">
              <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
                Copiar API
              </q-tooltip>
            </q-btn>
          </template>
        </q-input>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
const dataLink = process.env.VUE_APP_DATA_API;
const apiPath = 'feedcriativos';

import affiliateService from '../../services/AffiliateService';
import AffiliateMixin from '../../mixins/AffiliateMixin';
import SelectRequest from '../../widgets/inputsGlobal/SelectRequest';

export default {
  components: { SelectRequest },
  mixins: [affiliateService, AffiliateMixin],
  name: 'CouponAndOfferGenerator',
  data() {
    return {
      optionsDisplay: ['CSV', 'JSON'],
      creativeTypesOptions: [
        { value: 0, name: 'Todos' },
        { value: 1, name: 'Cupom' },
        { value: 2, name: 'Cupom e Ofertas' },
        { value: 5, name: 'Banner' }
      ],
      affiliateData: {
        email: null,
        id: null,
        token: null
      },
      apiDataUrl: dataLink,
      channel: null,
      channelID: null,
      display: null,
      creativeType: null,
      login: null
    };
  },
  watch: {
    channel() {
      this.selectChannel(this.channel);
    }
  },
  computed: {
    getProfiles: {
      get() {
        return this.$store.state.Account.user;
      }
    },
    getApiLink: {
      get() {
        if (this.channelID && this.display && this.creativeType) {
          return `${this.apiDataUrl}/${apiPath}?token=${this.affiliateData.token}&mode=${this.display}&canalid=${this.channelID}&affid=${this.affiliateData.id}&type=${this.creativeType.value}`;
        }

        return null;
      }
    }
  },
  methods: {
    selectChannel(value) {
      console.log(value);
      this.channelID = value;
    },

    // copyData(value) {
    //     this.copyToClipboard(value)
    //         .then(() => {
    //             this.successNotify("Copiado!");
    //         })
    //         .catch(() => {
    //             this.errorNotify("Erro ao copiar");
    //         });
    // },

    async setupAffiliateData() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getAffiliateData(this.getProfiles.affiliate_id);
        if (status === 200) {
          this.affiliateData = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  },
  created() {
    this.setupAffiliateData();
  }
};
</script>

<style scoped></style>
